/**
 * 包含一些工具类，通用的请求等
 */

const checkOneColumn = function (column, path, callback) {
  //不存在下级或者下级为0时，跳出递归
  if (column.children && column.children.length > 0) {
    for (let i = 0; i < column.children.length; i++) {
      checkOneColumn(column.children[i], path, callback)
    }
  }
  if (column.urls && (column.urls === path
    || path.indexOf(column.urls) === 0)) {
    if (callback) {
      callback(column)
    }
  }
}

export const activeColumn = function (columnItems, path) {
  let active = null
  for (let i = 0; i < columnItems.length; i++) {
    checkOneColumn(columnItems[i], path, function (column) {
      //修饰选中节点，增加顶级节点
      column['beginNode'] = columnItems[i]
      active = column
    })
    if (active) break
  }
  return active
}

const ONE_KB = 1000
const ONE_MB = ONE_KB * ONE_KB
const ONE_GB = ONE_KB * ONE_MB

export const humanReadableUnits = function (bytes) {
  let newSizeAndUnits
  if (bytes < ONE_KB) {
    newSizeAndUnits = bytes + ' 字节'
  } else if (bytes < ONE_MB) {
    newSizeAndUnits = parseFloat(bytes / ONE_KB).toFixed(2) + ' KB'
  } else if (bytes < ONE_GB) {
    newSizeAndUnits = parseFloat(bytes / ONE_MB).toFixed(2) + ' MB'
  } else {
    newSizeAndUnits = parseFloat(bytes / ONE_GB).toFixed(2) + ' GB'
  }
  return newSizeAndUnits
}

//简单的根据后缀名进行判断
export const fileIconFormat = function (fileName) {
  let format = ''
  if (fileName) {
    format = fileName.split('.').pop().toLocaleLowerCase() || ''
  }
  let type = 'file'
  if (['gif', 'jpg', 'jpeg', 'png', 'bmp', 'webp'].indexOf(format) > -1) {
    type = 'picture'
  }
  if (['mp4', 'm3u8', 'rmvb', 'avi', 'swf', '3gp', 'mkv', 'flv'].indexOf(format) > -1) {
    type = 'video-camera'
  }
  if (['mp3', 'wav', 'wma', 'ogg', 'aac', 'flac'].indexOf(format) > -1) {
    type = 'play-circle'
  }
  if (['txt', 'epub'].indexOf(format) > -1) {
    type = 'file-text'
  }
  if (['doc', 'docx', 'pages', 'epub', 'pdf'].indexOf(format) > -1) {
    type = 'file-word'
  }
  if (['pdf'].indexOf(format) > -1) {
    type = 'file-pdf'
  }
  if (['numbers', 'csv', 'xls', 'xlsx'].indexOf(format) > -1) {
    type = 'file-excel'
  }
  if (['keynote', 'ppt', 'pptx'].indexOf(format) > -1) {
    type = 'file-ppt'
  }
  return type
}

//根据文件类型判断展示图标 svg
export const fileTypeFormat = function (fileType) {
  let type = 'file'
  if (fileType) {
    if (fileType.indexOf('image/gif') !== -1) {
      type = 'file-gif'
    } else if (fileType.indexOf('image/jpeg') !== -1 || fileType.indexOf('image/jpg') !== -1) {
      type = 'file-jpg'
    } else if (fileType.indexOf('image/png') !== -1) {
      type = 'file-png'
    } else if (fileType.indexOf('image/svg') !== -1) {
      type = 'file-svg'
    } else if (fileType.indexOf('image/vnd.adobe.photoshop') !== -1) {
      type = 'file-psd'
    } else if (fileType.indexOf('image/') !== -1) {
      type = 'file-photo'
    } else if (fileType.indexOf('video/') !== -1) {
      type = 'file-video'
    } else if (fileType.indexOf('word') !== -1) { //word
      type = 'file-doc'
    } else if (fileType.indexOf('powerpoint') !== -1
      || fileType.indexOf('presentationml.presentation') !== -1) { //ppt
      type = 'file-ppt'
    } else if (fileType.indexOf('spreadsheetml.sheet') !== -1
      || fileType.indexOf('excel') !== -1) { //excel
      type = 'file-xls'
    } else if (fileType.indexOf('zip') !== -1
      || fileType.indexOf('rar') !== -1
      || fileType.indexOf('diskimage') !== -1
      || fileType.indexOf('java-archive') !== -1) { //zip war gz dmg
      type = 'file-zip'
    } else if (fileType.indexOf('audio') !== -1) {
      type = 'file-audio'
    }
  }
  return type
}

/**
 * 格式化毫秒为 ?天?小时?分钟?秒?格式
 * @param mss
 * @returns {string}
 */
export const formatDuring = function (mss) {
  const days = parseInt(mss / (1000 * 60 * 60 * 24))
  const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = parseInt((mss % (1000 * 60)) / 1000)
  let timeStr = ''
  if (days > 0) {
    timeStr += days + ' 天 '
  }
  if (hours > 0) {
    timeStr += hours + ' 小时 '
  }
  if (minutes > 0) {
    timeStr += minutes + ' 分 '
  }
  if (seconds > 0) {
    timeStr += seconds + ' 秒 '
  }
  return timeStr
}

const emoji = [':smile:', ':smirk:', ':sweat_smile:', ':laughing:', ':angry:', ':anguished:', ':astonished:', ':blush:', ':cold_sweat:', ':confounded:', ':confused:', ':cry:', ':relaxed:', ':satisfied:', ':scream:', ':disappointed:', ':dizzy_face:', ':expressionless:', ':fearful:', ':flushed:', ':wink:', ':grin:', ':grinning:', ':triumph:', ':unamused:', ':sleepy:', ':heart_eyes:', ':heart:', ':hushed:', ':innocent:', ':joy:', ':kiss:', ':kissing_heart:', ':sweat:', ':mask:', ':moon:', ':pensive:', ':persevere:', ':birthday:', ':sob:', ':sleeping:', ':weary:', ':sunglasses:', ':sunny:', ':thumbsup:', ':thumbsdown:', ':v:', ':dog:', ':watermelon:', ':strawberry:', ':rage:', ':relieved:', ':worried:', ':yum:', ':smiling_imp:', ':imp:', ':zap:', ':zzz:', ':punch:', ':girl:', ':rocket:', ':man:', ':woman:', ':rice:', ':ring:', ':pig:', ':soccer:', ':hand:', ':bomb:', ':books:', ':boy:', ':bug:', ':bulb:', ':cat:', ':cherries:', ':chicken:', ':clap:', ':cloud:', ':coffee:', ':cow:', ':watch:', ':eyeglasses:', ':eyes:', ':facepunch:', ':fire:', ':four_leaf_clover:', ':gem:', ':ghost:', ':gift:', ':key:', ':baby:', ':basketball:', ':bear:', ':koala:', ':beer:', ':beetle:', ':tada:', ':tiger:', ':train:', ':mouse:', ':muscle:', ':ok_hand:', ':pear:', ':skull:', ':pill:', ':poop:', ':pray:', ':snake:', ':snowman:', ':alien:']

export const emojiHtml = function (content) {
  if (content) {
    for (let i = 0, l = emoji.length; i < l; i++) {
      if (content.indexOf(emoji[i]) !== -1) {
        content = content.replace(eval('/' + emoji[i] + '/g'), '<img src="/static/images/face/emojipng/' + emoji[i].replace(/:/g, '') + '.png" alt="' + emoji[i] + '" class="emoji-png">')
      }
    }
  }
  return content
}

//插入到光标位置
export const insertText = function (obj, str, value) {
  if (typeof obj.selectionStart === 'number' && typeof obj.selectionEnd === 'number') {
    let startPos = obj.selectionStart
    let endPos = obj.selectionEnd
    let cursorPos = startPos
    value = value.substring(0, startPos) + str + value.substring(endPos, value.length)
    cursorPos += str.length
    obj.selectionStart = obj.selectionEnd = cursorPos
  } else {
    value += str
  }
  return value
}

const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/

/**
 * #fff #333333 转 rgb 色
 * @param color
 * @returns {string}
 */
export const colorRgb = function (color) {
  let sColor = color.toLowerCase()
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    //处理六位的颜色值
    let sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    return sColorChange.join(',')
  } else {
    return sColor
  }
}

/* eslint-disable no-undef */

// eslint-disable-next-line no-extend-native
String.prototype.width = function (font) {
  let f = font || '12px'
  const o = $('<div>' + this + '</div>')
    .css({ 'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font-size': f })
    .appendTo($('body'))
  const w = o.width()
  o.remove()
  return w
}

// eslint-disable-next-line no-extend-native
String.prototype.height = function (font) {
  let f = font || '12px'
  const o = $('<div>' + this + '</div>')
    .css({ 'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font-size': f })
    .appendTo($('body'))
  const h = o.height()
  o.remove()
  return h
}

export const buildMarkerLabelOffset = (text, font, offset, width, height) => {
  let value = '(20,20)'
  const w = parseInt(text.width(font))
  const h = parseInt(text.height(font))
  // 根据 offset 计算位置
  if (offset === 'top') {
    value = '(' + (-w / 2 + (width - 19) / 2 + 2) + ',' + -30 + ')'
  } else if (offset === 'right') {
    value = '(' + (width + 1) + ',' + (height - h - 6) / 2 + ')'
  } else if (offset === 'bottom') {
    value = '(' + (-w / 2 + (width - 19) / 2 + 2) + ',' + height + ')'
  } else if (offset === 'left') {
    value = '(' + (-w - 16) + ',' + (height - h - 6) / 2 + ')'
  }
  return value
}

export const throttle = (fn, context, delay, text, mustApplyTime) => {
  clearTimeout(fn.timer)
  fn._cur = Date.now()  //记录当前时间

  if (!fn._start) {      //若该函数是第一次调用，则直接设置_start,即开始时间，为_cur，即此刻的时间
    fn._start = fn._cur
  }
  if (fn._cur - fn._start > mustApplyTime) {
    //当前时间与上一次函数被执行的时间作差，与mustApplyTime比较，若大于，则必须执行一次函数，若小于，则重新设置计时器
    fn.call(context, text)
    fn._start = fn._cur
  } else {
    fn.timer = setTimeout(function () {
      fn.call(context, text)
    }, delay)
  }
}